import { Alert, AlertTitle, Button, CircularProgress, Stack } from "@mui/material";

import {
  ButtonRow,
  EStrikeOAuthFeature,
  useAccount,
  useExternalPopup,
  useStrikeConfiguration,
  useToast,
} from "@synota-io/synota-shared-ui";

interface Props {
  features?: Array<EStrikeOAuthFeature>;
  onDisconnect?(): void;
  onConnect?(): void;
}

export const StrikeFeatureForm = ({
  features = [
    EStrikeOAuthFeature.AutomaticPayout,
    EStrikeOAuthFeature.FromStrikePayments,
    EStrikeOAuthFeature.GetBalance,
  ],
  onDisconnect = () => {},
  onConnect = () => {},
}: Props) => {
  const { jwt, isAdmin } = useAccount();
  const { warning } = useToast();

  const { open, externalPopup, hasClosed } = useExternalPopup(() => {
    if (!hasAllFeatures && featureOAuthUrl) {
      refetch();
      onConnect();
    }
  });

  const {
    hasAllFeatures,
    hasSomeFeatures,
    featureOAuthUrl,
    isFetching: isLoadingStrike,
    refetch,
    getFeatureOAuthUrl,
    logoutOAuth,
  } = useStrikeConfiguration({ features });

  const onConnectToStrikeClick = () => {
    getFeatureOAuthUrl(features, {
      onSuccess: (response: any) => {
        if (!hasAllFeatures && response.data) {
          open({
            url: response.data,
            title: "Authorize your Strike Account",
            height: screen.height ? screen.height - 140 : 1024,
            width: 600,
          });
        }
      },
    });
  };

  const onLogoutStrikeClick = () => {
    logoutOAuth(
      { jwt },
      {
        onSuccess: () => {
          refetch();
          onDisconnect();
          warning("Strike account is now disconnected");
        },
      },
    );
  };

  const isPopupLoading = externalPopup && !hasClosed && !hasAllFeatures;
  const isConnectDisabled = Boolean(!isAdmin || isLoadingStrike || isPopupLoading);

  return (
    <ButtonRow spacing={2} alignItems="center" minHeight="3em">
      {hasSomeFeatures ? (
        <>
          <Stack>
            <Alert severity="success">
              <AlertTitle>Strike Account Linked</AlertTitle>
            </Alert>
          </Stack>
          {!isLoadingStrike && isAdmin && (
            <Button
              disabled={isConnectDisabled}
              size="small"
              color="error"
              onClick={onLogoutStrikeClick}
            >
              Disconnect
            </Button>
          )}
        </>
      ) : (
        <Button
          disabled={isConnectDisabled}
          variant="contained"
          size="small"
          onClick={onConnectToStrikeClick}
        >
          Connect to Strike
        </Button>
      )}
      {isLoadingStrike || isPopupLoading ? <CircularProgress size={32} /> : null}
    </ButtonRow>
  );
};
