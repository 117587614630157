import {
  dayjs,
  IContract,
  REPORT_ENDPOINTS,
  useSettlementSummary,
} from "@synota-io/synota-shared-ui";
import { ContractReportDownloader } from "./ContractReportDownloader";

export const ContractAuditReport = ({ contract }: { contract: IContract }) => {
  const { summary } = useSettlementSummary({
    contractUuid: contract.uuid,
    prefetch: false,
    itemsPerPage: 1,
  });
  if (!contract.hasAuditReport) {
    return null;
  }

  const [lastInvoice] = summary;
  const lastInvoiceDate = dayjs(lastInvoice?.date).startOf("day");

  return (
    <ContractReportDownloader
      startDate={lastInvoiceDate.subtract(6, "days")}
      endDate={lastInvoiceDate.endOf("day")}
      report={REPORT_ENDPOINTS.AUDIT}
      contract={contract}
    />
  );
};
