import {
  ConfirmationModal,
  FormButton,
  useAccount,
  useApiFieldErrors,
  useConfirmationModal,
  useToast,
  useUsersMutations,
} from "@synota-io/synota-shared-ui";
import { UserGroupTableRowProps } from "./UserGroupTable";
import { Stack } from "@mui/material";
import { DeleteForever, ReplyAll } from "@mui/icons-material";
import { useEffect, useState } from "react";

export const UserActionsRow = ({ user, onRefetch }: UserGroupTableRowProps) => {
  const toast = useToast();
  const confirmationModal = useConfirmationModal();

  const [sentInvitation, setSentInvitation] = useState(false);

  useEffect(() => {
    if (sentInvitation) {
      const timeout = setTimeout(() => {
        setSentInvitation(false);
      }, 10 * 1000);
      return () => clearTimeout(timeout);
    }
  }, [sentInvitation]);

  const { jwt } = useAccount();
  const {
    isLoading,
    deletePendingUser,
    deletePendingUserResponse,
    reinviteUser,
    reinviteUserResponse,
  } = useUsersMutations();

  useApiFieldErrors(
    deletePendingUserResponse?.data,
    "There was a problem deleting the user, please try again",
  );
  useApiFieldErrors(
    reinviteUserResponse?.data,
    "There was a problem sending the invitation, please try again",
  );

  const actionsDisabled = user.isAdmin && user.role !== "pending_backup_admin";

  return (
    <Stack direction="row" gap={2}>
      {user.status === "Pending" ? (
        <>
          <FormButton
            tooltip="Resend Invitation"
            isLoading={isLoading}
            disabled={sentInvitation || actionsDisabled}
            color="primary"
            disablePadding
            size="small"
            onClick={() => {
              reinviteUser(
                { jwt, user_email: user.email },
                {
                  onSuccess({ data }) {
                    if (data.success) {
                      setSentInvitation(true);
                      onRefetch();
                      toast.success("A new invitation was sent to " + user.email + "!");
                    }
                  },
                },
              );
            }}
          >
            <ReplyAll />
          </FormButton>
          <FormButton
            tooltip="Delete"
            isLoading={isLoading}
            disabled={actionsDisabled}
            color="error"
            disablePadding
            size="small"
            onClick={() => {
              confirmationModal.onOpen({
                title: "Delete " + user.email + "?",
                acceptLabel: "Delete",
                onConfirm() {
                  deletePendingUser(
                    { jwt, email_to_delete: user.email },
                    {
                      onSuccess({ data }) {
                        if (data.success) {
                          onRefetch();
                          confirmationModal.onClose();
                          toast.success(user.email + " has been deleted!");
                        }
                      },
                    },
                  );
                },
              });
            }}
          >
            <DeleteForever fontSize="small" />
          </FormButton>
        </>
      ) : null}
      <ConfirmationModal isLoading={isLoading} {...confirmationModal} />
    </Stack>
  );
};
