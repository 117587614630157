import { Download } from "@mui/icons-material";
import { Card, CardContent, Slide, Stack, Typography } from "@mui/material";
import {
  ActionButton,
  DateField,
  Dayjs,
  dayjs,
  IContract,
  IReportEndpoint,
  IReportsFilters,
  isDayjs,
  useDefaultError,
  useDownloadContractReport,
  useReportFiltersForm,
} from "@synota-io/synota-shared-ui";
import { useWatch } from "react-hook-form";

interface Props {
  contract: IContract | null;
  report: IReportEndpoint;
  hideEndDate?: boolean;
  startDate?: Dayjs;
  endDate?: Dayjs;
}

export const ContractReportDownloader = ({ startDate, endDate, contract, report }: Props) => {
  const defaultDates = {
    contract,
    startDate: startDate || dayjs().subtract(4, "days").startOf("day"),
    endDate: endDate || dayjs(),
  };

  const { control } = useReportFiltersForm({
    defaultReport: report,
    defaultDates,
    disableSearchParams: true,
  });

  const values = useWatch<IReportsFilters>({ control });

  const { fetch, isLoading, error } = useDownloadContractReport({
    contract: contract,
    path: report.path,
    label: report.label,
    startDate: isDayjs(values.startDate) ? values.startDate : null,
    endDate: isDayjs(values.endDate) ? values.endDate : null,
  });

  useDefaultError(error, "There was a problem downloading the report, please try again");

  const isMenuDisabled = !contract || isLoading;

  return (
    <Slide in direction="left">
      <Card>
        <CardContent>
          <Stack
            flexGrow={1}
            alignItems={{ xs: "start", lg: "center" }}
            direction={{ xs: "column", lg: "row" }}
            spacing={{ xs: 2, lg: 8 }}
          >
            <Typography sx={{ whiteSpace: "nowrap" }} variant="h5">
              {report.label} Report
            </Typography>
            <Stack direction="row" width="100%" spacing={{ xs: 4, lg: 8 }}>
              <DateField
                variant="filled"
                maxDate={isDayjs(values.endDate) ? values.endDate : dayjs()}
                disabled={isMenuDisabled}
                control={control}
                name="startDate"
                slotProps={{ textField: { size: "small" } }}
                label="Select Range Start"
                fullWidth
              />
              <DateField
                variant="filled"
                minDate={isDayjs(values.startDate) ? values.startDate : undefined}
                maxDate={dayjs()}
                disabled={isMenuDisabled}
                control={control}
                name="endDate"
                slotProps={{ textField: { size: "small" } }}
                label="Select Range End"
                fullWidth
              />
              <Stack flexGrow={1}>
                <ActionButton
                  sx={{ flexShrink: 0 }}
                  color="primary"
                  title={`Download ${report.label} Report`}
                  size="small"
                  type="submit"
                  onClick={fetch}
                  isLoading={isLoading}
                >
                  <Download fontSize="small" />
                </ActionButton>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Slide>
  );
};
